<template>
    <div class="w-full" v-if="this.fields.length > 0">
        <form :class="data.Class" :style="data.Style" :id="data.Id" v-if="data.Id">
            <div :class="item.Class" v-for="(item, n) in fields" :key="n">
                <inputtext v-if="item.Type === 'inputtext'" :data="item" />
                <combobox v-if="item.Type === 'combobox'" :data="item" />
                <checkbox v-if="item.Type === 'checkbox'" :data="item" />
            </div>
        </form>
        <div :class="data.Class" :style="data.Style" v-else>
            <div :class="item.Class" v-for="(item, n) in fields" :key="n">
                <inputtext v-if="item.Type === 'inputtext'" :data="item" />
                <combobox v-if="item.Type === 'combobox'" :data="item" />
                <checkbox v-if="item.Type === 'checkbox'" :data="item" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        Inputtext: () => import('@/components/general/inputtext'),
        Combobox: () => import('@/components/general/combobox'),
        Checkbox: () => import('@/components/general/checkbox')
    },
    props: {
        data: {
            type: Object
        },
        prev: {
            type: Object
        }
    },
    data () {
        return {
            fields: []
        }
    },
    methods: {
        async init () {
            this.fields = []
            this.data.Fields.forEach(item => {
                this.fields.push({ ...item })
            })
            if (!this.prev) return false
            this.fields.forEach(item => {
                if (item.NativeValue) {
                    item.NativeValue = this.stringReader(item.NativeValue, this.prev)
                    item.Label = this.stringReader(item.Label, this.prev)
                }
            })
        }
    },
    mounted () {
        this.init()
    }
}
</script>
